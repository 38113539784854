import React from "react";
import LoginFrom from "../login/form";
import WelcomeNotes, { RelatedLinks } from "../login/info";
import LoginBgShapes from "../login/shapes";

function LoginView() {
  return (
    <section
      className="login-section flex justify-center items-center overflow-hidden relative w-full h-full min-w-full min-h-screen bg-[#252457]"
      aria-label="Login page with welcome notes and login form"
    >
      {/* Shape Case */}
      <LoginBgShapes  cardShape={true}/>
      {/* User login form */}
      <div className="login-form-case p-5 md:p-10 w-4/5 md:w-3/4 max-w-[460px] md:max-w-[520px] h-[460px] md:h-[480px] drop-shadow-xl relative z-40 rounded-[20px]">
        <WelcomeNotes />
        <LoginFrom />
        <RelatedLinks />
      </div>
    </section>
  );
}

export default LoginView;
