import { useAtom } from "jotai";
import React, { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AllPages from "./component/pages";
import Alert from "./component/ui/alert";
import Spinner from "./component/ui/loader/spinner";
import { ModalProvider } from "./component/ui/modal/modal.context";
import { useServiceWorker } from "./hooks/useServiceworker";
import { CartProvider } from "./store/quick-cart/cart.context";
import { loaderAtom } from "./store/reward";
import ManagedDrawer from "./component/ui/drawer/manage-drawer"
const ManagedModal = lazy(() => import("./component/ui/modal/manage-modal"));
const ToastContainer = lazy(() => import("./component/ui/toastify"));
const contextClass = {
  success: "bg-[#706eac]",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

function App() {
  const [showAlert, setShowAlert] = useState(false);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [loading] = useAtom(loaderAtom);

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [waitingWorker, showReload]);

  return (
    <>
      {showAlert ? (
        <Alert onClose={() => setShowAlert(false)} onReload={reloadPage} />
      ) : null}
      {loading && <Spinner />}
      <ModalProvider>
        <Router>
          <Suspense fallback={<Spinner insideSection={true} />}>
            <CartProvider>
              <AllPages />
              <ManagedModal />
              <ManagedDrawer />
              <ToastContainer
                autoClose={3500}
                theme="colored"
                toastClassName={({ type }) =>
                  contextClass[type || "default"] +
                  " relative flex p-1 min-h-[64px] rounded-md justify-between overflow-hidden cursor-pointer"
                }
              />
            </CartProvider>
          </Suspense>
        </Router>
      </ModalProvider>
    </>
  );
}

export default App;
