import React, { lazy, useState } from "react";
import { useNavigate } from "react-router";
import { useToken } from "../../hooks/useToken";
import { validateLoginInputs } from "../utils/validateLoginInput";
import users from "../../data/users.json"
import { clearRewardFilterAtom } from "../../store/reward";
import { useAtom } from "jotai";
import { useCart } from "../../store/quick-cart/cart.context";

const UserSelectBox = lazy(()=>import("./listbox-select"));
const PasswordInput = lazy(()=>import("./password-input"));

function LoginFrom() {
  const navigate = useNavigate();
  const [, clearRewardFilters] = useAtom(clearRewardFilterAtom);
  const [selected, setSelected] = useState(users[0])
  const [password, setPassword] = useState(users[0].password);
  const [errors, setErrors] = useState(null);
  const { setToken, hasToken, getToken, removeToken } = useToken();
  const { resetCart } = useCart();

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputValidation = validateLoginInputs({ selected, password });
    if (inputValidation) {
      setErrors(inputValidation);
    } else {
      if (errors) setErrors(null);
      if(hasToken()){
        const oldToken = getToken();
        if(oldToken){
          const oldUser =  JSON.parse(oldToken)
          if(oldUser?.id !== selected?.id) {
            removeToken();
            resetCart()
          }
        } 
      }
      const newToken = JSON.stringify(selected)
      setToken(newToken);
      clearRewardFilters();
      navigate("/");
    }
  };

  const handleSelectUser = (userData) => {
    setSelected(userData);
    setPassword(userData.password);
  }

  return (
    <form onSubmit={handleSubmit} aria-label="Login form of Neo bank account">
      {/* User select box */}
        <UserSelectBox  selected={selected} setSelected={handleSelectUser} />

      {/* Password Input box */}
      <div className="w-full mt-8">
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Please enter your password"
        />
        {(errors && errors?.password) && <p className="my-2 text-xs text-red-500">{errors?.password}</p>}
      </div>
      <div className="w-1/2 m-auto md:w-full mt-8">
        <button
          type="submit"
          className="login-button w-full !h-10 md:!h-12 !text-[#f9f9f9] !text-base  md:!text-lg rounded-full"
          aria-label="Login button"
        >
          Login
        </button>
      </div>
    </form>
  );
}

export default LoginFrom;
