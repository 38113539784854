import React from "react";
import { useWindowSize } from "react-use";

function LoginBgShapes({ cardShape }) {
  const { width } = useWindowSize();
  return (
    <div className="shape-case absolute top-0 left-0 w-full h-full z-10 bg-transparent">
      {/* Shape -- Big star */}
      <div className="star-big absolute top-0 left-0 w-[200px] h-[100px]">
        <picture>
          <source type="image/avif" srcSet="./star-big.avif 1600w" />
          <source type="image/webp" srcSet="./star-big.webp 1600w" />
          <img
            src="./star-big.png"
            className="star-big absolute left-1 md:left-10  top-[35px] w-10 h-10 aspect-auto"
            alt="big star shape"
            loading="lazy"
          />
        </picture>
      </div>
      {/* Shape -- dummy Neptune */}
      <div className="solar-sys absolute left-0 top-[50%] w-[200px] h-[100px]">
        <div className="orbit absolute left-2 md:inset-1/2 w-14 h-14">
          <div className="neptune w-14 h-14 rounded-full "></div>
        </div>
      </div>
      {/* Shape -- Small star */}
      {width > 1023 && (
        <div className="star-small absolute bottom-0 right-0 w-[200px] h-[100px]">
          {/* <img
            src="./star-small.png"
            className="star-small absolute left-[100px] md:left-0 md:top-[35px] w-8 h-8 aspect-square"
            alt="small star shape"
          /> */}
          <picture>
            <source type="image/avif" srcSet="./star-big.avif 1600w" />
            <source type="image/webp" srcSet="./star-big.webp 1600w" />
            <img
              src="./star-big.png"
              className="star-small absolute left-[100px] md:left-0 md:top-[35px] w-8 h-8 aspect-square"
              alt="small star shape"
              loading="lazy"
            />
          </picture>
        </div>
      )}
      {/* Shape -- Card demo */}
      {width > 1023 && cardShape && (
        <div className="demo-card absolute top-2 right-2 w-[164px] h-[112px] md:w-[335px] md:h-[240px] rotate-3">
          <picture>
            <source
              type="image/avif"
              srcSet="./card.avif 1179w, ./med/card.avif 1600w"
            />
            <source
              type="image/webp"
              srcSet="./card.webp 1179w, ./med/card.webp 1600w"
            />
            <img
              fetchpriority="high"
              src="./card.png"
              className="card-shape w-full h-full aspect-auto"
              alt="card shape"
            />
          </picture>
        </div>
      )}
    </div>
  );
}

export default LoginBgShapes;
