import React from 'react';

const initialState = {
    position: null,
  isOpen: false,
  infoData: null,
};

function modalReducer(state, action) {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        position: action.position,
        infoData: action.payload,
        isOpen: true,
      };
    case 'close':
      return {
        ...state,
        position: null,
        infoData: null,
        isOpen: false,
      };
    default:
      throw new Error('Unknown infoBoxModal Action!');
  }
}

const InfoBoxModalStateContext = React.createContext(initialState);
InfoBoxModalStateContext.displayName = 'InfoBoxModalStateContext';
const InfoBoxModalActionContext = React.createContext(undefined);
InfoBoxModalActionContext.displayName = 'InfoBoxModalActionContext';

export const InfoBoxModalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <InfoBoxModalStateContext.Provider value={state}>
      <InfoBoxModalActionContext.Provider value={dispatch}>
        {children}
      </InfoBoxModalActionContext.Provider>
    </InfoBoxModalStateContext.Provider>
  );
};

export function useInfoBoxModalState() {
  const context = React.useContext(InfoBoxModalStateContext);
  if (context === undefined) {
      throw new Error(`useInfoBoxModalState must be used within a ModalProvider`);
    }
  return context;
}

export function useInfoBoxModalAction() {
  const dispatch = React.useContext(InfoBoxModalActionContext);
  if (dispatch === undefined) {
    throw new Error(`useInfoBoxModalAction must be used within a ModalProvider`);
  }
  return {
    openInfoBoxModal(position, payload) {
      dispatch({ type: 'open', position, payload });
    },
    closeInfoBoxModal() {
      dispatch({ type: 'close' });
    },
  };
}
