import React from "react";
import { Link } from "react-router-dom";


export const RelatedLinks = () => {
  return (
    <div className="w-full mt-8 flex justify-between items-center">
      <Link to={"#"} aria-label="Register page link">
        <span className="text-base text-[#F4F4F5]">Register</span>
      </Link>
      <Link to={"#"} aria-label="Forgot password page link">
        <span className="text-base text-[#7D7CA1] hover:text-[#F4F4F5]">
          Forgot Password?
        </span>
      </Link>
    </div>
  );
};

function WelcomeNotes() {
  return (
    <>
      <h1 className="text-xl md:text-3xl font-medium text-[#F9F9F9] text-center mb-1.5 lg:mb-3">
        Login Kard
      </h1>
      <p className="text-sm md:text-lg text-[#F9F9F9] text-center">Password Login</p>
    </>
  );
}

export default WelcomeNotes;
