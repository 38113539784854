import { Fragment, useEffect, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';

const Drawer = ({
  children,
  open = false,
  variant = 'right',
  useBlurBackdrop,
  onClose,
}) => {
  const ref = useRef();

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 h-full overflow-hidden"
        ref={ref}
        static
        open={open}
        onClose={onClose}
      >
        <div className="min-h-full py-4 md:py-5 md:px-5 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-dark bg-opacity-40 w-full h-full" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="-right-[100%]"
            enterTo="right-0"
            leave="ease-in duration-200"
            leaveFrom="right-0"
            leaveTo="-right-[100%]"
          >
            <div
                className={
                  variant === 'right'
                    ? 'absolute inset-y-0 flex max-w-full outline-none right-0'
                    : 'absolute inset-y-0 flex max-w-full outline-none left-0'
                }
              >
                <div className="h-full w-screen max-w-md">
                  <div className="drawer flex h-full flex-col bg-transparent text-base shadow-xl">
                    <div className="h-full w-full">{children}</div>
                  </div>
                </div>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default Drawer;
