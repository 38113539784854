import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { useToken } from "../../hooks/useToken";
import { ROUTES } from "../utils/routes";
import PrivateRoute from "./private-routes";
import LoginView from "../views/login";

const RouterGuard = ({ children }) => {
  const { hasToken } = useToken();

  const isAuthorized = hasToken();

  return isAuthorized ? (
    <PrivateRoute>{children}</PrivateRoute>
  ) : (
    <Navigate replace to={ROUTES.LOGIN} />
  );
};

function AllPages() {
  return (
      <Routes>
        <Route path={ROUTES.LOGIN} element={<LoginView />} exact />
        <Route path="/*" element={<RouterGuard />} />
      </Routes>
  );
}

export default AllPages;
