export function validateLoginInputs({selected, password}){
    
    if(!password){
        return {
            password: "Password is required"
        }
    } else if (password.length < 8){
        return {
            password: "Password must be at least 8 characters"
        }
    } else if (password !== selected.password) {
        return {
            password: "Incorrect password"
        }
    }
    
    return null
}