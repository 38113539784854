import { lazy } from "react";
import { Route, Routes } from "react-router";
import { InfoBoxModalProvider } from "../ui/map-infobox/infobox-modal.context";
import { ROUTES } from "../utils/routes";
const MinimalLayout = lazy(() => import("../layout"));
const HomePage = lazy(() => import("../views/home"));
const RewardsListView = lazy(() => import("../views/rewards"));
const ActivityPage = lazy(() => import("../views/activity"));
const ShopPage = lazy(() => import("../views/shop"));
const CheckoutPage = lazy(() => import("../views/checkout"));

const PrivateRoute = () => {
  return (
      <MinimalLayout>
        <Routes>
          <Route index element={<HomePage />} />
          <Route
            path={ROUTES.REWARDS}
            exact
            element={
              <InfoBoxModalProvider>
                <RewardsListView />
              </InfoBoxModalProvider>
            }
          />
          <Route path={ROUTES.ACTIVITY} exact element={<ActivityPage />} />
          <Route path={ROUTES.SHOP} exact element={<ShopPage />} />
          <Route path={ROUTES.CHECKOUT} exact element={<CheckoutPage />} />
        </Routes>
      </MinimalLayout>
  );
};

export default PrivateRoute;
