import React from "react";
import { CloseIcon } from "../icons/close-icon";

function Alert({onClose, onReload}) {
  return (
    <div className="alert fixed top-4 right-4 z-50 bg-[#765FFF] rounded-[12px] max-w-[300px] w-full">
      <header className="w-full border-b border-[#53528B] px-3 py-2 flex justify-between items-center">
        <h3 className="text-base text-[#2B2A66] font-medium">Updates</h3>
        <div>
        <button className="w-8 h-8 text-base text-[#F96975] font-medium bg-[#2B2A66] rounded-full flex items-center justify-center" onClick={onClose}>
            <CloseIcon className="w-4 h-4" />
        </button>
        </div>
      </header>
      <div className="flex w-full p-3 gap-2 justify-between items-center">
        <h3 className="text-sm text-[#2B2A66] font-medium">
          {/* An update of this app is available, please refresh. */}
          An Updated version is available, please refresh!
        </h3>
        <button className="px-4 py-2 text-sm refresh-button text-[#F4F4F5] font-medium rounded-full" onClick={onReload}>
         <span>Refresh</span>
        </button>
      </div>
    </div>
  );
}

export default Alert;


// const { waitingWorker, showReload, reloadPage } = useServiceWorker();
// decides when to show the toast
// useEffect(() => {
//     if (showReload && waitingWorker) {
//       showToast({
//         description: (
//           <div>
//             A new version of this page is available
//             <button onClick={() => reloadPage()}>REFRESH</button>
//           </div>
//         ),
//       });
//     } else closeToast();
//   }, [waitingWorker, showReload, reloadPage]);