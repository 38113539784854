import { lazy } from 'react';
import { useAtom } from 'jotai';
import { drawerAtom } from '../../../store/drawer-atom';
import Drawer from './drawer';
const CartSidebarView = lazy(
  () => import('./cart-sidebar')
);


export default function ManagedDrawer() {
  const [{ display, view, data }, setDrawerState] = useAtom(drawerAtom);
  return (
    <Drawer
      open={display}
      onClose={() => setDrawerState({ display: false, view: '' })}
      variant={
        [
          'MAIN_MENU_VIEW',
        ].includes(view)
          ? 'left'
          : 'right'
      }
    >
      {view === 'cart' && <CartSidebarView />}
      
    </Drawer>
  );
}
